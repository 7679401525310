import React from "react"
import ContentLayout from "./ContentLayout.js"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import Logo from "../images/logo.svg"

const Navigation = props => {
  return (
    <ContentLayout bg={props.bg} paddingTop="64px" paddingBottom="0px">
      <Link to="/">
        <Logo
          css={css`
            width: 144px;
          `}
        />
      </Link>
    </ContentLayout>
  )
}

export default Navigation
