import React from "react"
import ContentLayout from "./ContentLayout.js"
import { css } from "@emotion/core"
import { colors } from "../utils/theme.js"
import TypeformLink from "./TypeformLink"
import { useIntl } from "gatsby-plugin-intl"
import { breakpoints } from "../utils/theme.js"

const Banner = props => {
  const intl = useIntl()

  return (
    <ContentLayout
      bg={colors.primary.dark}
      paddingTop="8px"
      paddingBottom="8px"
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          gap: 8px;

          @media (max-width: ${breakpoints.xs}) {
            flex-direction: column;
            text-align: center;
          }
        `}
      >
        <p
          css={css`
            text-align: center;
            color: white;
            font-weight: 600;
          `}
        >
          {intl.formatMessage({ id: "banner-closed-beta" })}
        </p>
        <TypeformLink />
      </div>
    </ContentLayout>
  )
}

export default Banner
