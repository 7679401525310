/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "./layout.css"
import React from "react"
import PropTypes from "prop-types"
import Navigation from "./navigation"
import Banner from "./banner"
import Footer from "./footer"

const PageLayout = ({ navColor, children }) => {
  return (
    <>
      <Banner bg={navColor} />
      <Navigation bg={navColor} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageLayout
