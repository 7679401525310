import React, { useEffect } from "react"
import ContentLayout from "./ContentLayout"
import { css } from "@emotion/core"
import { useIntl, IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

export default props => {
  const intl = useIntl()
  const languageName = {
    en: "English (EN)",
    fr: "Français (FR)",
  }

  return (
    <div>
      <ContentLayout bg={props.bg}>
        <div
          css={css`
            font-size: 13px;
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            © {new Date().getFullYear()} Zenshare.{" "}
            {intl.formatMessage({ id: "copyright" })}
          </div>
          <div
            css={css`
              display: flex;
            `}
          >
            <IntlContextConsumer>
              {({ languages, language: currentLocale }) =>
                languages.map(language => (
                  <a
                    key={language}
                    onClick={() => changeLocale(language)}
                    style={{
                      color: currentLocale === language ? `black` : `grey`,
                      margin: 10,
                      textDecoration: `underline`,
                      cursor: `pointer`,
                    }}
                  >
                    {languageName[language]}
                  </a>
                ))
              }
            </IntlContextConsumer>
          </div>
        </div>
      </ContentLayout>
    </div>
  )
}
