export const breakpoints = {
  xs: "575px",
  sm: "768px",
  md: "992px",
  lg: "1200px",
}

export const colors = {
  primary: {
    light: "#F6F9FA",
    base: "#69A1AE",
    dark90: "#5E97A7E6",
    dark: "#5E97A7",
  },
}
