/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { breakpoints } from "../utils/theme"

const ContentLayout = props => {
  return (
    <div
      css={css`
        background-color: ${props.bg};
        padding-top: ${props.paddingTop ? props.paddingTop : "80px"};
        padding-bottom: ${props.paddingBottom ? props.paddingBottom : "80px"};

        @media (max-width: ${breakpoints.sm}) {
          padding-top: ${props.paddingTop ? props.paddingTop : "48px"};
          padding-bottom: ${props.paddingBottom ? props.paddingBottom : "48px"};
        }
      `}
    >
      <div
        css={css`
          margin: 0 auto;
          padding-left: 24px;
          padding-right: 24px;

          @media (min-width: ${breakpoints.xs}) {
            max-width: auto;
          }
          @media (min-width: ${breakpoints.sm}) {
            max-width: 930px;
          }
          @media (min-width: ${breakpoints.md}) {
            max-width: 930px;
          }
          @media (min-width: ${breakpoints.lg}) {
            max-width: 930px;
          }
        `}
      >
        <main>{props.children}</main>
      </div>
    </div>
  )
}

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContentLayout
