import React from "react"
import { colors } from "../utils/theme"
import { css } from "@emotion/core"
import * as typeformEmbed from "@typeform/embed"
import { useIntl } from "gatsby-plugin-intl"

const TypeformLink = () => {
  const intl = useIntl()
  return (
    <div
      onClick={() =>
        typeformEmbed.makePopup("https://cycodron.typeform.com/to/BwXcI5", {
          mode: "popup",
          autoOpen: true,
        })
      }
      css={css`
        text-decoration: underline;
        color: white;
        font-weight: 600;
        &:hover {
          cursor: pointer;
        }
      `}
    >
      <p>{intl.formatMessage({ id: "banner-request-access" })}</p>
    </div>
  )
}

export default TypeformLink
